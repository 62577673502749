// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import jQuery from 'jquery';
window.$ = jQuery;

import slick from '@accessible360/accessible-slick'

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

/*
 * Header
 */
$('.dropdown').on('click', function (e) {
  const $active = $('.dropdown-active');
  ($active.get(0) !== this) && $active.attr('class', 'dropdown');
  $(this).toggleClass("dropdown-active");
});

$(document).on('click', function(e) {
  const active = document.querySelector('.dropdown-active');
  (!!active && !active.contains(e.target)) && $(active).attr('class', 'dropdown');
})

$('#menuBtn').on('click', function () {
  $('#mobileMenu').css('transform', 'translateY(0)');
  $('#mobileMenu').css('top', '120px');
  $(this).hide();
  $('#menuClose').show();
});

$('#menuClose').on('click', function () {
  $('#mobileMenu').css('transform', 'translateY(-100%)');
  $('#mobileMenu').css('top', '0');
  $(this).hide();
  $('#menuBtn').show();
});

$(window).on('resize', function() {
  if (($(window).width() > 768) & ($("#menuClose").css("display") == "block")) {
    $('#mobileMenu').css('transform', 'translateY(-100%)');
    $('#mobileMenu').css('top', '0');
    $('#menuClose').hide();
  };
  if (($(window).width() < 768) & ($("#menuBtn").css("display") == "none") & ($("#menuClose").css("display") == "none")) {
    $('#menuBtn').show();
  };
});

if (document.querySelector('.current-user').dataset.currentUser === 'true') {
  document.querySelectorAll('.login-btn').forEach(node => {
    node.innerHTML = 'Secure Portal';
    $(node).after("<a class=\"logout-link\" href=\"/sign-out\">Sign Out</a>");
  });
} else {
  document.querySelectorAll('.login-btn').forEach(node => {
    node.innerHTML = 'Sign In';
  });
}

$(function () {
  $('.slideshow-container').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    useAutoplayToggleButton: false,
  })
})

/*
 * Audit Form
 */
if (document.location.pathname === '/audit-form') {
  $('.number-field').on('keyup', _toCurrency);

  /*
   * Form submission
   */
  document.querySelector("form[action='/form_submissions']").addEventListener("submit", (e) => {
    e.preventDefault();
    const formInputs = e.target.querySelectorAll("input[id], select, textarea");
    const formData = new Map();
    let formId = '';
    for (let i = 0; i < formInputs.length; i++) {
      const input = formInputs[i];
      if (input.id === 'form_submission_form_id') {
        formId = input.value
      } else {
        formData.set(input.id.replace('form_submission_', ''), input.value);
      }
    };

    fetch('/form_submissions', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        form_submission: {
          policy_number: formData.get('policy_number'),
          owner: formData.get('owner'),
          form_id: formId,
          submission: JSON.stringify(Array.from(formData.entries())),
        }
      }),
    }).then((e) => e.json()).then((e) => {
      if (e.status === 'success') {
        fetch('/inquiry', {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            contact: {
              name: formData.get('owner'),
            }
          }),
        })
        document.querySelector("form").style.display = "none";
        document.querySelector(".form-submit-message").style.display = "block";
        document.body.scrollTop = 0; // for Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
      }
    });

  })

}

/*
 * Questionnaire
 */

if (document.location.pathname === '/client-questionnaire') {
  $('.number-field').on('keyup', _toCurrency);
  $('input[type=tel]').on('keyup', _toTelephone);
  $('.ssn-field').on('keyup', _toSSN);
  /*
   * Child fieldset
   */
  document.querySelector(".btn-add-child").addEventListener("click", (e) => {
    e.preventDefault();
    const hiddenFieldsets = Array.from(document.querySelectorAll('.child-fieldset')).filter(fieldset => fieldset.style.display === 'none');
    if (hiddenFieldsets.length === 1) e.currentTarget.style.display = 'none';
    hiddenFieldsets[0].style.display = 'block';
  });
  const removeChildBtns = document.querySelectorAll(".btn-remove-child");
  for (let i = 0; i < removeChildBtns.length; i++) {
    const removeChildBtn = removeChildBtns[i];
    removeChildBtn.addEventListener("click", (e) => {
      e.preventDefault();
      const visibleFieldsets = Array.from(document.querySelectorAll('.child-fieldset')).filter(fieldset => fieldset.style.display === 'block');
      if (visibleFieldsets.length === 4) document.querySelector('.btn-add-child').style.display = 'block';
      const targetFieldset = e.currentTarget.parentNode;
      const spouseFieldset = targetFieldset.nextElementSibling;
      targetFieldset.style.display = 'none';
      if (spouseFieldset.style.display === 'block') {
        spouseFieldset.querySelector('.btn-remove-childs-spouse').click();
      };
    });
  }

  /*
  * Child's Spouse fieldset
  */
  const addChildsSpouseBtns = document.querySelectorAll('.btn-add-childs-spouse');
  for (let i = 0; i < addChildsSpouseBtns.length; i++) {
    const addChildsSpouseBtn = addChildsSpouseBtns[i];
    addChildsSpouseBtn.addEventListener("click", (e) => {
      e.preventDefault();
      e.currentTarget.parentNode.nextElementSibling.style.display = 'block';
      e.currentTarget.style.display = 'none';
    });
  }
   const removeChildsSpouseBtns = document.querySelectorAll(".btn-remove-childs-spouse");
   for (let i = 0; i < removeChildsSpouseBtns.length; i++) {
     const removeChildsSpouseBtn = removeChildsSpouseBtns[i];
     removeChildsSpouseBtn.addEventListener("click", (e) => {
       e.preventDefault();
       e.currentTarget.parentNode.previousElementSibling.querySelector('.btn-add-childs-spouse').style.display = 'block';
       e.currentTarget.parentNode.style.display = 'none';
     });
   }

  /*
   * Fieldsets that repeat
   */
  initAddAndRemoveBtns('grandchild');
  initAddAndRemoveBtns('great-grandchild');
  initAddAndRemoveBtns('property');
  initAddAndRemoveBtns('investment');
  initAddAndRemoveBtns('retirement');
  initAddAndRemoveBtns('business-asset');
  initAddAndRemoveBtns('insurance');
  initAddAndRemoveBtns('liability');
  initAddAndRemoveBtns('salary');
  initAddAndRemoveBtns('spouses-salary');

  /*
   * Fieldsets that do not repeat
   */
  initAddAndRemoveBtnsNoRepeat('spouse');
  initAddAndRemoveBtnsNoRepeat('expenses');
  initAddAndRemoveBtnsNoRepeat('retirement-income');
  initAddAndRemoveBtnsNoRepeat('spouses-estate');

  /*
   * Add event handlers for add and remove buttons on single fieldsets
   */
  function initAddAndRemoveBtnsNoRepeat (className) {
    document.querySelector(`.btn-add-${className}`).addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(`.${className}-fieldset`).style.display = 'block';
      e.currentTarget.style.display = 'none';
    });
    document.querySelector(`.btn-remove-${className}`).addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(`.btn-add-${className}`).style.display = 'block';
      document.querySelector(`.${className}-fieldset`).style.display = 'none';
    });
  }
  /*
   * Add event handlers for add and remove buttons on repeateable fieldsets
   */
  function initAddAndRemoveBtns (className) {
    document.querySelector(`.btn-add-${className}`).addEventListener("click", (e) => {
      e.preventDefault();
      const hiddenFieldsets = Array.from(document.querySelectorAll(`.${className}-fieldset`)).filter(fieldset => fieldset.style.display === 'none');
      if (hiddenFieldsets.length === 1) e.currentTarget.style.display = 'none';
      hiddenFieldsets[0].style.display = 'block';
    });
    const removeBtns = document.querySelectorAll(`.btn-remove-${className}`);
    for (let i = 0; i < removeBtns.length; i++) {
      const removeBtn = removeBtns[i];
      removeBtn.addEventListener("click", (e) => {
        e.preventDefault();
        const visibleFieldsets = Array.from(document.querySelectorAll(`.${className}-fieldset`)).filter(fieldset => fieldset.style.display === 'block');
        if (visibleFieldsets.length === 4) document.querySelector(`.btn-add-${className}`).style.display = 'block';
        const targetFieldset = e.currentTarget.parentNode;
        targetFieldset.style.display = 'none';
      });
    }
  }

  /*
   * Submit form
   */
  document.querySelector("form[action='/form_submissions']").addEventListener("submit", (e) => {
    e.preventDefault();
    const formInputs = e.target.querySelectorAll("input[id], select, textarea");
    const formData = new Map();
    let formId = '';
    for (let i = 0; i < formInputs.length; i++) {
      const input = formInputs[i];
      if (input.id === 'form_submission_form_id') {
        formId = input.value
      } else if (input.type === 'checkbox') {
        const key = input.id.replace('form_submission_', '');
        if (input.checked) {
          formData.set(key, input.value)
        } else if (!formData.has(key)) {
          formData.set(key, '');
        }
      } else if (input.type === 'radio') {
        const key = input.id
          .replace('form_submission_', '')
          .replace('_yes', '')
          .replace('_no', '')
          .replace('_single', '')
          .replace('_married', '')
          .replace('_separated', '')
          .replace('_divorced', '')
          .replace('_term', '')
          .replace('_variable_life', '')
          .replace('_universal_life', '')
          .replace('_private_placement_life', '')
          .replace('_disability', '')
          .replace('_long_care', '')
          .replace('_conservative', '')
          .replace('_moderate', '')
          .replace('_aggressive', '')
          .replace('_sole_proprietor', '')
          .replace('_c_corp', '')
          .replace('_s_corp', '')
          .replace('_limited_liability_corp', '')
          .replace('_family_limited_partnership', '');
        if (input.checked) {
          formData.set(key, input.value);
        } else if (!formData.has(key)) {
          formData.set(key, '');
        }
      } else {
        formData.set(input.id.replace('form_submission_', ''), input.value);
      }
    };

    fetch('/form_submissions', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        form_submission: {
          advisor: formData.get('info_advisor'),
          agency: formData.get('info_agency'),
          owner: formData.get('client_name'),
          form_id: formId,
          submission: JSON.stringify(Array.from(formData.entries())),
        }
      }),
    }).then((e) => e.json()).then((e) => {
      if (e.status === 'success') {
        const name = formData.get('client_name');
        const phone = formData.get('client_home_phone');
        const email = formData.get('client_email');
        fetch('/inquiry', {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            contact: {
              name: name ? name : 'not given',
              phone: phone ? phone : 'not given',
              email: email ? email : 'not given',
            }
          }),
        })
        document.querySelector(".form-disclaimer").style.display = "none";
        document.querySelector("form").style.display = "none";
        document.querySelector(".form-submit-message").style.display = "block";
        document.body.scrollTop = 0; // for Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
      }
    });
  })
}

/**
 * Estate Tax Calculator
 */

if (document.location.pathname === '/estate-tax-calculator') {
  // lazy man fix to only run if form has already been submitted
  if (document.querySelectorAll('input[readonly]').length > 0) {
    document.querySelector('select[name="single_or_married"]').addEventListener('input', function(e) {
      e.preventDefault();
      document.querySelector('form')?.submit();
    });
  }

  $('.number-field').on('keyup', _toCurrency);

  if ($('.estate-tax-calculator-headers-row').length > 0) {
    $('.estate-tax-calculator-btn').html('Re-calculate');
    $('select[name="single_or_married"]').on('change', function(e) {
      if ($('#start-year').val() && $('input[name="gross_estate_tax_base"]').val()) $('.financial-form').trigger('submit');
    })
  }
}

/*
 *
 */
Array.from(document.querySelectorAll('legend')).forEach((legend) => {
  legend.setAttribute('class', 'h3');
})

/*
 * Contact Form
 */
if (document.location.pathname === '/contact') {
  $('input[type=tel]').on('keyup', _toTelephone);

  _isElementLoaded('.hs-button').then((element) => {
    element.addEventListener('click', () => {
      document.body.scrollTop = 0; // for Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    })
  })
}

/*
 * Helper function
 */
function _toCurrency(e) {
  let firstPart = '';
  let secondPart = '';
  const oldVal = $(this).val();
  const indexOfDecimal = oldVal.indexOf('.');
  if (indexOfDecimal > -1) {
    firstPart = oldVal.slice(0, indexOfDecimal);
    secondPart = oldVal.slice(indexOfDecimal + 1);
  } else {
    firstPart = oldVal;
  }
  const firstDigits = !!firstPart ? firstPart.replace(/\D+/g, '') : '';
  const nextFirst = firstDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const nextSecond = secondPart.slice(0, 2);
  const nextVal = indexOfDecimal > -1 ? nextFirst + '.' + nextSecond : nextFirst;

  return $(this).val(nextVal);
}
function _toTelephone(e) {
  const oldVal = $(this).val();
  const cleanedVal = oldVal.replace(/\D+/g, '');
  const sub1 = cleanedVal.slice(0,3);
  const sub2 = cleanedVal.slice(3,6);
  const sub3 = cleanedVal.slice(6,10);
  let nextVal = '';

  if (!!sub1) nextVal = `(${sub1}`
  if (!!sub2) nextVal += `) ${sub2}`;
  if (!!sub3) nextVal += `-${sub3}`;

  return $(this).val(nextVal);
}
function _toSSN(e) {
  const oldVal = $(this).val();
  const cleanedVal = oldVal.replace(/\D+/g, '');
  const sub1 = cleanedVal.slice(0,3);
  const sub2 = cleanedVal.slice(3,5);
  const sub3 = cleanedVal.slice(5,9);
  let nextVal = '';

  if (!!sub1) nextVal = `${sub1}`
  if (!!sub2) nextVal += `-${sub2}`;
  if (!!sub3) nextVal += `-${sub3}`;

  return $(this).val(nextVal);
}

async function _isElementLoaded (selector) {
  while (document.querySelector(selector) === null) {
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
  return document.querySelector(selector);
};